import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { getWordsCount } from 'shared/strings'

import {
  Container,
  Grid,
} from '@material-ui/core'

import TvScriptsHeader from './header'

import { TV_SCRIPTS_VIDEO, TV_SCRIPTS_AUDIO, TV_SCRIPTS_GRAPHICS } from '../../../const'
import { BlocksList } from '../render-blocks'

import css from './tvscripts.module.scss'

function TvScriptsDocument({
  blocks,
  setBlocks,
  documentData,
  setDocument,
}) {
  const setBlockData = ({ index, data }) => {
    const newBlockData = { ...blocks[index], ...data }
    const allBlocks = [...blocks]
    allBlocks.splice(index, 1, newBlockData)
    setBlocks([...allBlocks])
  }

  const createBlock = ({ type = 'video' }) => {
    const introBlock = []
    const otherBlocks = []

    blocks.map((block, index) => {
      if (index === 0) {
        introBlock.push(block)
      } else {
        otherBlocks.push(block)
      } return null
    })

    if (type === 'video') {
      const newBlockData = [...introBlock, { ...TV_SCRIPTS_VIDEO }, ...otherBlocks]
      setBlocks([...newBlockData])
    } if (type === 'audio') {
      const newBlockData = [...introBlock, { ...TV_SCRIPTS_AUDIO }, ...otherBlocks]
      setBlocks([...newBlockData])
    } if (type === 'graphics') {
      const newBlockData = [...introBlock, { ...TV_SCRIPTS_GRAPHICS }, ...otherBlocks]
      setBlocks([...newBlockData])
    }
  }

  const { timeCoef } = documentData

  const updateTimeCoef = (coefficient) => {
    const newDoc = {
      ...documentData,
      timeCoef: coefficient,
    }
    setDocument(newDoc)
  }

  const totalTime = () => {
    const blocksTime = blocks.map((block) => Math.ceil(timeCoef * getWordsCount({ string: block.text })))
    const reducer = (accumulator, currentValue) => accumulator + currentValue
    return blocksTime.reduce(reducer, 0)
  }

  return (
    <>
      <div className={css.form__head}>
        <Container>
          <TvScriptsHeader documentData={documentData} totalTime={totalTime} setDocument={(props) => setDocument(props)} />
        </Container>
      </div>
      <div className={classNames(css.form__main)}>
        <div className={css['form__scroll-wrapper']}>
          <div className={css.form__spacer} />
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <BlocksList
                  updateTimeCoef={updateTimeCoef}
                  blocks={blocks}
                  setBlockData={setBlockData}
                  setBlocks={setBlocks}
                  category="tvscripts"
                  createBlock={createBlock}
                  timeCoef={timeCoef}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  )
}

TvScriptsDocument.propTypes = {
  documentData: PropTypes.objectOf(PropTypes.any).isRequired,
  setDocument: PropTypes.func.isRequired,
  blocks: PropTypes.objectOf(PropTypes.any).isRequired,
  setBlocks: PropTypes.func.isRequired,
}

export default TvScriptsDocument
