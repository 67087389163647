import React from 'react'
import PropTypes from 'prop-types'
import { FOOTAGE_COLLECTION, TVSCRIPTS_COLLECTION } from 'const'
import FootageDocument from './footage'
import TvScriptsDocument from './tvscripts'

export const renderDocument = ({
  scriptCategory,
  documentData,
  blocks,
  setDocument,
  setBlocks,
  titleError,
}) => {
  if (scriptCategory === FOOTAGE_COLLECTION) {
    return (
      <FootageDocument
        documentData={documentData}
        blocks={blocks}
        setDocument={setDocument}
        setBlocks={setBlocks}
        titleError={titleError}
      />
    )
  } if (scriptCategory === TVSCRIPTS_COLLECTION) {
    return (
      <TvScriptsDocument
        documentData={documentData}
        blocks={blocks}
        setDocument={setDocument}
        setBlocks={setBlocks}
        titleError={titleError}
      />
    )
  }
}

renderDocument.propTypes = {
  scriptCategory: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  documentData: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  blocks: PropTypes.object.isRequired,
  setDocument: PropTypes.func.isRequired,
  setBlocks: PropTypes.func.isRequired,
  titleError: PropTypes.string.isRequired,
}
