import React from 'react'

import Header from 'pages/partials/header'
import Aside from './aside'
import Preview from './preview'

import css from './main.module.scss'

const HomePage = () => (
  <>
    <Header />
    <div className={css.main}>
      <aside className={css.main__aside}>
        <Aside />
      </aside>
      <div className={css.main__content}>
        <Preview />
      </div>
    </div>
  </>
)

export default HomePage
