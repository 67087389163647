const monthNames = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12',
}

export const getDate = (documentData) => {
  const date = documentData.dateCreated.seconds ? documentData.dateCreated.toDate() : null
  const dateString = `${date ? new Date(date).toUTCString() : ''}`
  const dateSplit = dateString.split(' ')
  const isDateExist = !!documentData.dateCreated.seconds
  const getMonthName = () => {
    const month = () => (dateSplit[2].includes(',') ? dateSplit[2].replace(',', '') : dateSplit[2])
    return monthNames[month()]
  }
  return isDateExist ? `${dateSplit[1]}/${getMonthName()}/${dateSplit[3]}` : 'No date'
}

export const getModifiedDate = (documentData) => {
  const date = documentData.lastModified.seconds ? documentData.lastModified.toDate() : null
  const dateString = `${date ? new Date(date).toUTCString() : ''}`
  const dateSplit = dateString.split(' ')
  const isDateExist = !!documentData.lastModified.seconds

  return isDateExist ? `${dateSplit[1]}/${monthNames[dateSplit[2]]}/${dateSplit[3]}` : 'No date'
}

export const getDateFromUnix = (dateUnix) => {
  const date = dateUnix.seconds ? dateUnix.toDate() : null
  const dateString = `${date ? new Date(date) : ''}`
  const dateSplit = dateString.split(' ')
  return `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[3]}`
}

export const getNumberDateFromUnix = (dateUnix) => {
  const date = dateUnix.seconds ? dateUnix.toDate() : null
  const dateString = `${date ? new Date(date).toUTCString() : ''}`
  const dateUTCString = dateString.replace(',', '')
  const dateSplit = dateUTCString.split(' ')
  return `${dateSplit[1]}/${monthNames[dateSplit[2]]}/${dateSplit[3]}`
}

export const getDateToJsonFromUnix = (dateUnix) => {
  const date = dateUnix.seconds ? dateUnix.toDate() : null
  const dateString = `${date ? new Date(date) : ''}`
  const dateSplit = dateString.split(' ')
  return `${dateSplit[3]}-${monthNames[dateSplit[1]]}-${dateSplit[2]}T12:00:00.000Z`
}
