export const secondsToString = ({ seconds, withLabel = false }) => {
  const minutes = Math.floor(seconds / 60)
  const remainSeconds = seconds - minutes * 60
  const format = (t) => {
    if (t < 10) {
      return `0${t}`
    }
    return t
  }
  if (withLabel) {
    return `${format(minutes)} min ${format(remainSeconds)} sec`
  }
  return `${format(minutes)}-${format(remainSeconds)}`
}
