import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  iconButtonAdd: {
    padding: 10,
    minWidth: 50,
    marginLeft: '12px',
    color: 'white',
    backgroundColor: 'var(--indigo)',
    '&:hover': {
      backgroundColor: 'var(--indigo)',
    },
  },
}))
