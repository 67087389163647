import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(1),
    display: 'flex',
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    },
  },
  'box--editor': {
    height: '100%',
  },
  box__timer: {
    marginLeft: 'auto',
  },
  language: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: '8px',
    height: '100%',
  },
  label: {
    fontSize: '12px',
  },
}))
