import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router'
import Clock from 'react-live-clock'

import {
  Container,
  Grid,
  AppBar,
  Button,
  Box,
} from '@material-ui/core'
import firebase from 'firebase'
import { ApplicationContext } from 'context'

import styles from './header.module.scss'

// eslint-disable-next-line react/prop-types
function Header({ history }) {
  const [date, setDate] = useState(null)

  const {
    user,
    setCurrentUser,
  } = useContext(ApplicationContext)

  const signOut = async (event) => {
    event.preventDefault()
    try {
      await firebase
        .auth()
        .signOut()
      setCurrentUser(null)
      history.push('/login')
    } catch (error) {
      console.log('error', error)
    }
  }


  useEffect(() => {
    const currentDate = Date.now()
    setDate(currentDate)
  }, [])

  return (
    <header>
      <AppBar position="static" className={styles.header}>
        <Container>
          <Grid container>
            <Grid item xs={3}>
              <Button className={styles.header__button} onClick={signOut}>Log out</Button>
            </Grid>
            <Grid item xs={3}>
              <span>
                {'GTM '}
                <Clock date={date} format="HH:mm YYYY-MM-DD" ticking interval={100000} timezone="GMT" />
              </span>
            </Grid>
            <Grid item xs={3}>
              <span>
                {'LOCAL '}
                <Clock date={date} format="HH:mm YYYY-MM-DD" ticking interval={100000} />
              </span>
            </Grid>
            <Grid item xs={3}>
              <Box fontWeight={300} textAlign="right">
                {(user && user.email) || ''}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
    </header>
  )
}

export default withRouter(Header)
