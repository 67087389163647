const FOOTAGE_COLLECTION = 'footage'
const TVSCRIPTS_COLLECTION = 'tvscripts'
const AUDIO_BLOCK = 'sot'
const VIDEO_BLOCK = 'video'
const INTRO_BLOCK = 'intro'
const GRAPHICS_BLOCK = 'graphics'

export {
  FOOTAGE_COLLECTION,
  TVSCRIPTS_COLLECTION,
  AUDIO_BLOCK,
  VIDEO_BLOCK,
  INTRO_BLOCK,
  GRAPHICS_BLOCK,
}
