import React from 'react'
import PropTypes from 'prop-types'
import { TVSCRIPTS_COLLECTION } from 'const'
import { stripHtml } from 'shared/strings'
import './style.scss'

const Block = ({
  type, description, block, category,
}) => {
  if (category === TVSCRIPTS_COLLECTION) {
    return (
      <div className="block">
        <span className="block__type">
          <span className="block__subtitle">
            {type}
          </span>
          <span className="block__info">
            {block.source && <span>{block.source}</span>}
            {block.description && <span>{block.description}</span>}
          </span>
        </span>
        <span className="block__description">
          {block.text && <span>{stripHtml(block.text)}</span>}
        </span>
      </div>
    )
  } return (
    <div className="block">
      <span className="block__type">
        <span className="block__subtitle">
          {type}
        </span>
        <span className="block__info">
          {block.source && <span>{block.source}</span>}
          {block.name && <span>{block.name}</span>}
          {block.title && <span>{block.title}</span>}
          {block.location && <span>{block.location}</span>}
          {block.accessRestriction && <span>{block.accessRestriction}</span>}
        </span>
      </span>
      <span className="block__description">
        {description}
      </span>
    </div>
  )
}

Block.propTypes = {
  type: PropTypes.string,
  description: PropTypes.string,
  category: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  block: PropTypes.object,
}

Block.defaultProps = {
  type: '',
  description: '',
  category: '',
  block: {},
}

export default Block
