import { LoginPage, CreateScriptPage, ScriptPage } from '../pages'
import { ROUTE_NAMES } from './constants'

const { LOGIN_PAGE, CREATE_SCRIPT, SCRIPT } = ROUTE_NAMES

export const routes = [
  {
    path: LOGIN_PAGE,
    name: 'LOGIN_PAGE',
    component: LoginPage,
    exact: true,
  },
  {
    path: CREATE_SCRIPT,
    name: 'CREATE_SCRIPT',
    component: CreateScriptPage,
    exact: false,
  },
  {
    path: SCRIPT,
    name: 'SCRIPT',
    component: ScriptPage,
    exact: false,
  },
]
