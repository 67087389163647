import { DEFAULT_COEF } from 'components/timer/const'

const DEFAULT_AUDIO = {
  accessRestriction: 'ACCESS ALL',
  acquisition: null,
  description: '',
  in: 0,
  language: 'en-US',
  location: '',
  coordinates: [],
  name: '',
  out: 0,
  source: '',
  title: '',
  type: 'sot',
  objectRecognition: false,
  celebrityDetection: false,
  sound2text: true,
  faceDetection: false,
}

const DEFAULT_VIDEO = {
  accessRestriction: 'ACCESS ALL',
  acquisition: null,
  description: '',
  in: 0,
  location: '',
  coordinates: [],
  out: 0,
  source: '',
  type: 'video',
  objectRecognition: true,
  celebrityDetection: false,
  sound2text: false,
  faceDetection: false,
}

const DEFAULT_FOOTAGE = {
  dateCreated: '',
  framerate: '25',
  lastEditedBy: '',
  locked: false,
  owner: '',
  submitted: false,
  title: '',
  fileName: '',
}

const DEFAULT_TVSCRIPTS = {
  title: '',
  client: '',
  editorialNotes: '',
  accessRestrictions: '',
  owner: '',
  bureau: '',
  billingNotes: '',
  dateCreated: '',
  clearedBy: '',
  submittedTo: '',
  timeCoef: DEFAULT_COEF,
}

const TV_SCRIPTS_INTRO = {
  type: 'intro',
  text: '',
}

const TV_SCRIPTS_VIDEO = {
  type: 'video',
  description: '',
  source: '',
  text: '',
}

const TV_SCRIPTS_AUDIO = {
  type: 'sot',
  description: '',
  source: '',
  text: '',
}

const TV_SCRIPTS_GRAPHICS = {
  type: 'graphics',
  description: '',
  source: '',
  text: '',
}

const FRAMERATE = [25, 29.97, 30, 50, 54.94, 60]

const CREATE_DEFAULT_DATA = {
  footage: DEFAULT_FOOTAGE,
  tvscripts: DEFAULT_TVSCRIPTS,
}

const DEFAULT_BLOCKS_DATA = {
  footage: [DEFAULT_VIDEO],
  tvscripts: [TV_SCRIPTS_INTRO, TV_SCRIPTS_VIDEO, TV_SCRIPTS_AUDIO, TV_SCRIPTS_GRAPHICS],
}

export {
  DEFAULT_AUDIO,
  DEFAULT_VIDEO,
  DEFAULT_FOOTAGE,
  FRAMERATE,
  CREATE_DEFAULT_DATA,
  DEFAULT_BLOCKS_DATA,
  TV_SCRIPTS_VIDEO,
  TV_SCRIPTS_AUDIO,
  TV_SCRIPTS_GRAPHICS,
}
