import React from 'react'
import {
  FOOTAGE_COLLECTION,
  TVSCRIPTS_COLLECTION,
  AUDIO_BLOCK,
  VIDEO_BLOCK,
  INTRO_BLOCK,
  GRAPHICS_BLOCK,
} from 'const'

import {
  FootageVideoCard,
  FootageAudioCard,
  TvScriptsIntro,
  TvScriptsAudio,
  TvScriptsVideo,
  TvScriptsGraphics,
} from './cards'

export const renderBlock = ({
  type, index, block, category, setBlockData, goUpBlock, goDownBlock, removeBlock, createBlock, updateTimeCoef, timeCoef,
}) => {
  if (category === FOOTAGE_COLLECTION) {
    if (type === VIDEO_BLOCK) {
      return (
        <FootageVideoCard
          key={`${category}-video-${index}`}
          data={block}
          index={index}
          setData={setBlockData}
          goUpBlock={() => goUpBlock({ currentIndex: index })}
          goDownBlock={() => goDownBlock({ currentIndex: index })}
          removeBlock={() => removeBlock({ currentIndex: index })}
        />
      )
    } if (type === AUDIO_BLOCK) {
      return (
        <FootageAudioCard
          key={`${category}-sot-${index}`}
          data={block}
          index={index}
          setData={setBlockData}
          goUpBlock={() => goUpBlock({ currentIndex: index })}
          goDownBlock={() => goDownBlock({ currentIndex: index })}
          removeBlock={() => removeBlock({ currentIndex: index })}
        />
      )
    }
  } if (category === TVSCRIPTS_COLLECTION) {
    if (type === INTRO_BLOCK) {
      return (
        <TvScriptsIntro
          key={`${category}-sot-${index}`}
          data={block}
          index={index}
          setData={setBlockData}
          goUpBlock={() => goUpBlock({ currentIndex: index })}
          goDownBlock={() => goDownBlock({ currentIndex: index })}
          removeBlock={() => removeBlock({ currentIndex: index })}
          createBlock={createBlock}
          updateTimeCoef={updateTimeCoef}
          timeCoef={timeCoef}
        />
      )
    }
    if (type === VIDEO_BLOCK) {
      return (
        <TvScriptsVideo
          key={`${category}-sot-${index}`}
          data={block}
          index={index}
          setData={setBlockData}
          goUpBlock={() => goUpBlock({ currentIndex: index })}
          goDownBlock={() => goDownBlock({ currentIndex: index })}
          removeBlock={() => removeBlock({ currentIndex: index })}
          updateTimeCoef={updateTimeCoef}
          timeCoef={timeCoef}
        />
      )
    }
    if (type === AUDIO_BLOCK) {
      return (
        <TvScriptsAudio
          key={`${category}-sot-${index}`}
          data={block}
          index={index}
          setData={setBlockData}
          goUpBlock={() => goUpBlock({ currentIndex: index })}
          goDownBlock={() => goDownBlock({ currentIndex: index })}
          removeBlock={() => removeBlock({ currentIndex: index })}
          updateTimeCoef={updateTimeCoef}
          timeCoef={timeCoef}
        />
      )
    }
    if (type === GRAPHICS_BLOCK) {
      return (
        <TvScriptsGraphics
          key={`${category}-sot-${index}`}
          data={block}
          index={index}
          setData={setBlockData}
          goUpBlock={() => goUpBlock({ currentIndex: index })}
          goDownBlock={() => goDownBlock({ currentIndex: index })}
          removeBlock={() => removeBlock({ currentIndex: index })}
          updateTimeCoef={updateTimeCoef}
          timeCoef={timeCoef}
        />
      )
    }
  }
}
