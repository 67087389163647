import React, { useState, useEffect, useContext } from 'react'
import {
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core'
import { ApplicationContext } from 'context'
import { FOOTAGE_COLLECTION, TVSCRIPTS_COLLECTION } from 'const'
import { SearchProvider } from 'context/search'
import { useStyles } from './styles'
import TabPanel from './tab-panel'
import ListWrapper from './list-wrapper'

import Search from './search'
import './style.scss'

const Aside = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(2)

  const tabs = [
    { label: 'TV Scripts', category: TVSCRIPTS_COLLECTION },
    { label: 'Radio Scripts', disabled: true, category: 'radioscripts' },
    { label: 'Footage', category: FOOTAGE_COLLECTION },
    { label: 'Handovers', disabled: true, category: 'handovers' },
  ]

  const {
    activeCategory: {
      active: lastActiveCategory,
    },
  } = useContext(ApplicationContext)

  const handleChange = (event, newValue) => {
    setActiveTabIndex(newValue)
  }

  useEffect(() => {
    if (lastActiveCategory) {
      const last = tabs.findIndex((item) => item.category === lastActiveCategory)
      const isExist = Number.isInteger(last)
      // // eslint-disable-next-line no-unused-expressions
      isExist && setActiveTabIndex(last)
    }
  }, [lastActiveCategory, tabs])

  const classes = useStyles()

  const tabsContent = [
    { index: 0 },
    { index: 1 },
    { index: 2 },
    { index: 3 },
  ]

  return (
    <>
      <AppBar position="static">
        <Tabs
          value={activeTabIndex}
          onChange={handleChange}
          aria-label="Categories"
          className={classes.tabs}
        >
          {tabs.map(({ label, disabled }) => (
            <Tab
              className={classes.tab}
              label={label}
              disabled={disabled}
              key={label}
            />
          ))}
        </Tabs>
      </AppBar>
      <SearchProvider>
        <div className="category">
          <div className="category__list">
            {tabsContent.map(({ index }) => (
              <TabPanel
                value={activeTabIndex}
                index={index}
              >
                <ListWrapper categoryName={tabs[activeTabIndex].category} />
              </TabPanel>
            ))}
          </div>
          <div className="category__search">
            <Search
              activeCategory={tabs[activeTabIndex].category}
            />
          </div>
        </div>
      </SearchProvider>
    </>
  )
}

export default Aside
