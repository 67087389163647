import PropTypes from 'prop-types'
import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { ROUTE_NAMES } from './constants'

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const userLoggedIn = window.localStorage.getItem('loggedIn')
  return (
    <Route
      render={
        (routeProps) => (userLoggedIn ? (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to={ROUTE_NAMES.LOGIN_PAGE} />
        ))
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
}

export default PrivateRoute
