import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ApplicationContext } from 'context'
import { List } from '@material-ui/core'
import { SearchContext } from 'context/search'

import { getNumberDateFromUnix } from 'shared/date'

import ListItem from './item'

import css from './list.module.scss'

const ScriptsList = ({ scripts, categoryName }) => {
  const [scriptsCollection, setScriptsCollection] = useState([])
  const isEmptyScripts = (coll) => coll.length === 0
  const {
    text,
  } = useContext(SearchContext)

  const {
    activeScript: {
      active: {
        id: activeId,
      },
      setActiveScript,
    },
  } = useContext(ApplicationContext)


  useEffect(() => {
    if (scripts && !text) {
      setScriptsCollection(scripts)
    } if (scripts && !!text) {
      const isStringIncludes = (string) => string.toLowerCase().includes(text.toLowerCase())

      const filtered = scripts.filter(({ title, dateCreated, owner }) => isStringIncludes(title)
        || isStringIncludes(getNumberDateFromUnix(dateCreated))
        || isStringIncludes(owner))

      setScriptsCollection(filtered)
    }
  }, [text, scripts])

  useEffect(() => {
    if (scripts !== scriptsCollection) {
      setScriptsCollection(scripts)
    }
  }, [scripts, scriptsCollection])

  const isScriptActive = (contextId, itemId) => contextId === itemId

  return (
    <List component="nav" aria-label="list">
      {isEmptyScripts(scriptsCollection) ? (
        <div className={css['no-match']}>No match</div>
      ) : (
        scriptsCollection.map(({
          title, dateCreated, owner, id, client, reviewed,
        }, index) => (
          <ListItem
            isActive={isScriptActive(activeId, id)}
            key={`${title}--${index}`}
            id={id}
            createdAt={getNumberDateFromUnix(dateCreated)}
            title={title}
            owner={owner}
            categoryName={categoryName}
            client={client || ''}
            reviewed={reviewed}
            setActive={setActiveScript}
          />
        ))
      )}
    </List>
  )
}

export default ScriptsList

ScriptsList.propTypes = {
  scripts: PropTypes.arrayOf(PropTypes.object),
  categoryName: PropTypes.string.isRequired,
}

ScriptsList.defaultProps = {
  scripts: [],
}
