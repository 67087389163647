import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { SearchContext } from 'context/search'

import {
  Paper,
  InputBase,
  IconButton,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import AddIcon from '@material-ui/icons/Add'

import { useStyles } from './styles'


function Search({
  activeCategory, history,
}) {
  const classes = useStyles()

  const {
    text,
    setText,
  } = useContext(SearchContext)


  const onChange = (event) => {
    setText(event.target.value)
  }

  const createScript = () => {
    // eslint-disable-next-line react/prop-types
    history.push(`/create/${activeCategory}`)
  }

  return (
    <>
      <Paper component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Search script"
          inputProps={{ 'aria-label': 'search script' }}
          onChange={onChange}
          value={text}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </Paper>
      <IconButton
        className={classes.iconButtonAdd}
        aria-label="add-script"
        onClick={createScript}
      >
        <AddIcon />
      </IconButton>
    </>
  )
}

Search.propTypes = {
  activeCategory: PropTypes.string,
}

Search.defaultProps = {
  activeCategory: '',
}

export default withRouter(Search)
