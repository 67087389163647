import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import LocationSelectInput from 'components/location'

import {
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core'

import MenuIcon from '@material-ui/icons/Menu'

import { getDateFromUnix } from 'shared/date'

import firebase from 'firebase'

import DateFnsUtils from '@date-io/date-fns'

import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'

import './card.scss'

const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(1),
    display: 'flex',
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    },
  },
}))

function VideoCard({
  data,
  setData,
  index,
  goUpBlock,
  goDownBlock,
  removeBlock,
}) {
  const classes = useStyles()
  /*eslint-disable */
  const {
    accessRestriction,
    acquisition,
    description,
    in: timeIn,
    location,
    objectRecognition,
    out: timeOut,
    source,
    sound2text,
    celebrityDetection,
    faceDetection,
  } = data
  /* eslint-enable */


  const changeField = (event) => {
    const { id, value } = event.target
    if (id === 'objectRecognition') {
      setData({ index, data: { [id]: !objectRecognition } })
    } else if (id === 'celebrityDetection') {
      setData({ index, data: { [id]: !celebrityDetection } })
    } else if (id === 'sound2text') {
      setData({ index, data: { [id]: !sound2text } })
    } else if (id === 'faceDetection') {
      setData({ index, data: { [id]: !faceDetection } })
    } else {
      setData({ index, data: { [id]: value } })
    }
  }

  const changeDate = (value) => {
    const timestamp = firebase.firestore.Timestamp.fromDate(value)
    setData({ index, data: { acquisition: timestamp } })
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const upAndClose = () => {
    goUpBlock()
    handleClose()
  }

  const downAndClose = () => {
    goDownBlock()
    handleClose()
  }

  const removeAndClose = () => {
    removeBlock()
    handleClose()
  }

  const getLocation = (place) => {
    setData({ index, data: { location: place.place, coordinates: place.coordinates } })
  }

  return (
    <div className="card">
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.box}>
            <span className="card__label">VIDEO</span>
            <div>
              <Button className="card__menu" variant="contained" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <MenuIcon />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={upAndClose}>Move Up</MenuItem>
                <MenuItem onClick={downAndClose}>Move Down</MenuItem>
                <MenuItem onClick={removeAndClose}>Remove</MenuItem>
              </Menu>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.box}>
            <TextField
              id="description"
              label="Description"
              fullWidth
              value={description}
              onChange={changeField}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className={classes.box}>
            <TextField
              id="source"
              label="Source"
              fullWidth
              value={source}
              onChange={changeField}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={5}>
          <div className={classes.box}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Date Shot"
                value={acquisition && getDateFromUnix(acquisition)}
                onChange={changeDate}
                fullWidth
              />
            </MuiPickersUtilsProvider>
          </div>
        </Grid>
        <Grid item xs={12} sm={2}>
          <div className={classes.box}>
            <FormControlLabel
              control={(
                <Checkbox
                  id="objectRecognition"
                  checked={objectRecognition}
                  onChange={changeField}
                  className="card__checkbox"
                  color="primary"
                  name="Object Recognition"
                />
              )}
              label="Object Recognition"
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={2}>
          <div className={classes.box}>
            <FormControlLabel
              control={(
                <Checkbox
                  id="celebrityDetection"
                  checked={celebrityDetection}
                  onChange={changeField}
                  color="primary"
                  className="card__checkbox"
                  name="Celebrity Detection"
                />
              )}
              label="Celebrity Detection"
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className={classes.box}>
            <TextField id="accessRestriction" label="Access restriction" value={accessRestriction} fullWidth onChange={changeField} />
          </div>
        </Grid>
        <Grid item xs={12} sm={5}>
          <div className={classes.box}>
            <LocationSelectInput
              location={location}
              getLocation={getLocation}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={2}>
          <div className={classes.box}>
            <FormControlLabel
              control={(
                <Checkbox
                  id="sound2text"
                  checked={sound2text}
                  onChange={changeField}
                  color="primary"
                  className="card__checkbox"
                  name="Sound To Text"
                />
              )}
              label="Sound To Text"
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={2}>
          <div className={classes.box}>
            <FormControlLabel
              control={(
                <Checkbox
                  id="faceDetection"
                  checked={faceDetection}
                  onChange={changeField}
                  color="primary"
                  name="Face Detection"
                  className="card__checkbox"
                />
              )}
              label="Face Detection"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default VideoCard
