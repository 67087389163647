import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DEFAULT_USER_INFO } from './const'

import firebase from '../firebase'

export const ApplicationContext = React.createContext({})

export const ApplicationProvider = ({ children = null }) => {
  const [user, setCurrentUser] = useState(null)
  const [activeCategory, setActiveCategory] = useState('')
  const [collection, setCollection] = useState([])
  const [activeScript, setActiveScript] = useState({ id: '', category: '' })

  useEffect(() => {
    firebase.auth().onAuthStateChanged((authStatus) => {
      if (authStatus && !user) {
        const db = firebase.firestore()
        const userDocRef = db.collection('users').doc(authStatus.email)
        userDocRef.get().then((doc) => {
          let userDoc
          if (doc.exists) {
            userDoc = doc.data()
          } else {
            userDoc = DEFAULT_USER_INFO
            db.collection('users').doc(authStatus.email.trim()).set(DEFAULT_USER_INFO)
          }
          setCurrentUser({ email: authStatus.email, ...userDoc })
        }).catch((error) => {
          console.log('error', error)
        })
      } else {
        window.localStorage.removeItem('loggedIn')
      }
    })
  }, [user])


  const setActiveCollection = (data) => {
    setCollection(data)
  }

  const setCategory = (data) => {
    setActiveCategory(data)
  }

  const contextValue = {
    user,
    setCurrentUser,
    collection: { data: collection, setActiveCollection },
    activeScript: {
      active: activeScript,
      setActiveScript,
    },
    activeCategory: {
      active: activeCategory,
      setCategory,
    },
  }

  return (
    <ApplicationContext.Provider value={contextValue}>
      {children}
    </ApplicationContext.Provider>
  )
}

ApplicationProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}
