import React, { useContext, useEffect } from 'react'
import classNames from 'classnames'
import { secondsToString } from 'shared/time'

import {
  TextField,
  Grid,
} from '@material-ui/core'
import { ApplicationContext } from 'context'
import { get } from 'lodash'
import { getNumberDateFromUnix } from 'shared/date'

import css from './tvscripts.module.scss'

const TvScriptsHeader = ({
  // eslint-disable-next-line react/prop-types
  documentData,
  totalTime,
  setDocument,
}) => {
  const {
    accessRestrictions,
    billingNotes,
    bureau,
    clearedBy,
    client,
    dateCreated,
    editorialNotes,
    owner,
    submittedTo,
    title,
  } = documentData

  const {
    user,
  } = useContext(ApplicationContext)

  const changeValue = ({ event, path, value = null }) => {

    setDocument({ ...documentData, [path]: value || (event && event.target.value) })
  }

  useEffect(() => {
    const ownerDefault = get(user, 'email', '')
    changeValue({ path: 'owner', value: ownerDefault })
  }, [user])

  // eslint-disable-next-line react/prop-types
  const { lastModified } = documentData

  return (
    <Grid container>
      <Grid item xs={12} sm={3}>
        <div className={classNames(css.form__header, css['form__header--height'])}>
          <TextField
            id="title"
            label="Title"
            className={classNames(css['form__header-input'], css['form__header-input--label-small'])}
            required
            value={title || ''}
            onChange={(event) => changeValue({ event, path: 'title' })}
          />
        </div>
        <div className={classNames(css.form__header, css['form__header--height'])}>
          <TextField
            id="owner"
            label="Owner"
            className={classNames(css['form__header-input'], css['form__header-input--label-small'])}
            required
            value={owner || ''}
          />
        </div>
        <div className={classNames(css.form__header, css['form__header--height'], css['form__header--between'])}>
          {lastModified && (
          <div>
            <div className={css['custom-label']}> Last Modified </div>
            {' '}
            {getNumberDateFromUnix(lastModified)}
          </div>
          )}
          {dateCreated && (
          <div>
            <div className={css['custom-label']}>Created </div>
            {' '}
            {getNumberDateFromUnix(dateCreated)}
          </div>
          )}
          <div />
        </div>
      </Grid>
      <Grid item xs={12} sm={3}>
        <div className={classNames(css.form__header, css['form__header--height'])}>
          <TextField
            id="client"
            label="Client"
            className={classNames(css['form__header-input'], css['form__header-input--label-small'])}
            value={client || ''}
            onChange={(event) => changeValue({ event, path: 'client' })}
          />
        </div>
        <div className={classNames(css.form__header, css['form__header--height'])}>
          <TextField
            id="bureau"
            label="Bureau"
            className={classNames(css['form__header-input'], css['form__header-input--label-small'])}
            value={bureau || ''}
            onChange={(event) => changeValue({ event, path: 'bureau' })}
          />
        </div>
        <div className={classNames(css.form__header, css['form__header--height'])}>
          <TextField
            id="cleared"
            label="QC'ed and cleared for submission"
            className={classNames(css['form__header-input'], css['form__header-input--label-small'])}
            value={clearedBy || ''}
            onChange={(event) => changeValue({ event, path: 'clearedBy' })}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={3}>
        <div className={classNames(css.form__header, css['form__header--height'])}>
          <TextField
            id="editorial"
            label="Editorial notes"
            className={classNames(css['form__header-input'], css['form__header-input--label-small'])}
            value={editorialNotes || ''}
            onChange={(event) => changeValue({ event, path: 'editorialNotes' })}
          />
        </div>
        <div className={classNames(css.form__header, css['form__header--height'])}>
          <TextField
            id="billing"
            label="Billing Notes"
            className={classNames(css['form__header-input'], css['form__header-input--label-small'])}
            value={billingNotes || ''}
            onChange={(event) => changeValue({ event, path: 'billingNotes' })}
          />
        </div>
        <div className={classNames(css.form__header, css['form__header--height'])}>
          <TextField
            id="submitted"
            label="Submitted to"
            className={classNames(css['form__header-input'], css['form__header-input--label-small'])}
            value={submittedTo || ''}
            onChange={(event) => changeValue({ event, path: 'submittedTo' })}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={3}>
        <div className={classNames(css.form__header, css['form__header--height'])}>
          {/*
          <TextField
            id="access"
            label="Access Restrictions"
            className={classNames(css['form__header-input'], css['form__header-input--label-small'])}
            value={accessRestrictions || ''}
            onChange={(event) => changeValue({ event, path: 'accessRestrictions' })}
          />
          */}
        </div>
        <div className={classNames(css.form__header, css['form__header--height'])}>
          <div>
            <div className={css['custom-label']}> Approximate Running Time </div>
            {' '}
            {secondsToString({ seconds: totalTime(), withLabel: true })}
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default TvScriptsHeader
