import React, { useState, useEffect } from 'react'
import DraftJS from 'text-editor/component'
import Timer from 'components/timer'

import {
  TextField,
  Grid,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core'

import { getWordsCount } from 'shared/strings'

import MenuIcon from '@material-ui/icons/Menu'
import { useStyles } from './styles'

import './card.scss'

function GraphicsCard({
  /*eslint-disable */
  data,
  setData,
  index,
  goUpBlock,
  goDownBlock,
  removeBlock,
  updateTimeCoef,
  timeCoef,
}) {
   /* eslint-enable */
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [wordsCount, setWordsCount] = useState(0)
  const [seconds, setSeconds] = useState(0)

  // eslint-disable-next-line react/prop-types
  const { description, text, source } = data

  const isBlockAfterIntro = () => index > 1

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  
  const handleClose = () => {
    setAnchorEl(null)
  }

  const upAndClose = () => {
    goUpBlock()
    handleClose()
  }

  const downAndClose = () => {
    goDownBlock()
    handleClose()
  }

  const removeAndClose = () => {
    removeBlock()
    handleClose()
  }

  const setText = (textHtml) => {
    setData({ index, data: { text: textHtml } })
  }
  const getHtml = (html) => {
    setText(html)
  }

  const changeValue = ({ event, path, value = null }) => {
    setData({ index, data: { ...data, [path]: value || event.target.value } })
  }

  const setCount = (count) => {
    setWordsCount(count)
  }

  const stopCallback = (time) => {
    // eslint-disable-next-line react/prop-types
    const wordsTotal = getWordsCount({ string: data.text })
    const newTimer = time / wordsTotal
    updateTimeCoef(newTimer.toFixed(2))
  }

  useEffect(() => {
    setSeconds(Math.ceil(timeCoef * wordsCount))
  }, [wordsCount, timeCoef])

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const { text: textHtml } = data
    if (seconds === 0 && textHtml) {
      const words = getWordsCount({ string: textHtml })
      setSeconds(Math.ceil(timeCoef * words))
      setWordsCount(words)
    }
  }, [])


  return (
    <div className="card">
      <Grid container>
        <Grid item xs={12} sm={4}>
          <div className={classes.box}>
            <span className="card__label">GRAPHICS</span>
            <div>
              <Button className="card__menu" variant="contained" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <MenuIcon />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {isBlockAfterIntro() && <MenuItem onClick={upAndClose}>Move Up</MenuItem>}
                <MenuItem onClick={downAndClose}>Move Down</MenuItem>
                <MenuItem onClick={removeAndClose}>Remove</MenuItem>
              </Menu>
            </div>
            <div className={classes.box__timer}>
              <Timer secondsProps={seconds} stopCallback={(time) => stopCallback(time)} />
            </div>
          </div>
          <div className={classes.box}>
            <TextField id="title" label="Description" fullWidth value={description || ''} onChange={(event) => changeValue({ event, path: 'description' })} />
          </div>
          <div className={classes.box}>
            <TextField id="title" label="Source" fullWidth value={source || ''} onChange={(event) => changeValue({ event, path: 'source' })} />
          </div>
        </Grid>
        <Grid item xs={12} sm={8}>
          <div className={`${classes.box} ${classes['box--editor']}`}>
            <DraftJS
              getHtml={getHtml}
              setWordsCount={setCount}
              defaultHtml={data && text}
              placeholder="Enter script for the graphics element here"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default GraphicsCard
