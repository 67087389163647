import React from 'react'
import PropTypes from 'prop-types'
import { FootagePreview, TvScriptsPreview } from './previews'

const ListItem = React.memo(({
  createdAt,
  title,
  owner,
  isActive,
  setActive,
  id: itemId,
  categoryName,
  client,
  reviewed,
}) => {
  const renderitem = () => {
    if (categoryName === 'footage') {
      return (
        <FootagePreview
          isActive={isActive}
          itemId={itemId}
          categoryName={categoryName}
          createdAt={createdAt}
          title={title}
          owner={owner}
          setActive={setActive}
        />
      )
    } if (categoryName === 'tvscripts') {
      return (
        <TvScriptsPreview
          isActive={isActive}
          reviewed={reviewed}
          itemId={itemId}
          categoryName={categoryName}
          createdAt={createdAt}
          title={title}
          owner={owner}
          setActive={setActive}
          client={client}
        />
      )
    } return null
  }

  return renderitem()
})

export default ListItem

ListItem.propTypes = {
  createdAt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  setActive: PropTypes.func,
  reviewed: PropTypes.bool.isRequired,
}


ListItem.defaultProps = {
  isActive: false,
  setActive: null,
}
