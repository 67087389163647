import PropTypes from 'prop-types'

import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react'
import { withRouter } from 'react-router'
import {
  Box,
  TextField,
  Button,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import { ApplicationContext } from 'context'
import styles from './login.module.scss'
import Header from '../partials/header'

import firebase from '../../firebase'

function LoginPage({ history }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [formError, setFormError] = useState(null)

  const { user } = useContext(ApplicationContext)

  const handleEmail = (event) => {
    setEmail(event.target.value)
  }

  const handlePassword = (event) => {
    setPassword(event.target.value)
  }

  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault()
      const { email: userEmail, password: userPassword } = event.target.elements
      try {
        setFormError(null)
        await firebase
          .auth()
          .signInWithEmailAndPassword(userEmail.value, userPassword.value)
        // eslint-disable-next-line no-undef
        window.localStorage.setItem('loggedIn', true)
        history.push('/')
      } catch (error) {
        setFormError(error.message)
        console.log('error', error)
      }
    },
    [history],
  )

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const userLoggenIn = window.localStorage.getItem('loggedIn')
    if (userLoggenIn && user) {
      history.push('/')
    }
  }, [user, history])

  return (
    <>
      <Header>Login Page</Header>
      <main className={styles.main}>
        <Box width="400px" component="div" m={1} boxShadow={2}>
          <form noValidate className={styles.main__form} onSubmit={handleLogin}>
            <div className={styles.main__input}>
              <span className={styles['main__icon-wrapper']}>
                <PersonIcon />
              </span>
              <TextField
                required
                type="email"
                fullWidth
                name="email"
                label="Email"
                onChange={handleEmail}
                value={email}
                error={!!formError}
                helperText={formError || ''}
              />
            </div>
            <div className={styles.main__input}>
              <span className={styles['main__icon-wrapper']}>
                <VpnKeyIcon />
              </span>
              <TextField
                required
                type="password"
                fullWidth
                name="password"
                label="Password"
                onChange={handlePassword}
                value={password}
                error={!!formError}
                helperText={formError || ''}
              />
            </div>
            <div className={styles['main__form-button']}>
              <Button variant="contained" color="primary" type="submit">Login</Button>
            </div>
          </form>
        </Box>
      </main>
    </>
  )
}

LoginPage.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default withRouter(LoginPage)
