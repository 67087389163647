import firebase from '../../firebase'

const db = firebase.firestore()

export const getCollection = async ({ collectionName = '' }) => {
  const data = await db.collection(collectionName)
    .get()
  return data
}

export const getCollectionByOwner = async ({ collectionName = '', owner = '' }) => {
  const data = await db.collection(collectionName)
    .where('owner', '==', owner)
    .get()
  return data
}

export const saveDoc = ({
  collectionName = '', saveCallBack = () => null, history = null, document,
}) => {
  db.collection(collectionName).add(document)
    .then((docRef) => {
      saveCallBack(docRef.id)
      setTimeout(() => {
        if (history) {
          history.push('/')
        }
      }, 300)
    })
    .catch((error) => {
      console.error('Error adding document: ', error)
    })
}

export const updateDoc = ({
  collectionName = '', updateCallback = () => null, documentId, document, history = null,
}) => {
  db.collection(collectionName).doc(documentId).update({ ...document })
    .then(() => {
      // eslint-disable-next-line no-unused-expressions
      updateCallback(documentId)
      setTimeout(() => {
        if (history) {
          history.push('/')
        }
      }, 300)
    })
    .catch((error) => {
      console.error('Error updating document: ', error)
    })
}

export const removeDoc = ({ collectionName, id }) => (
  db.collection(collectionName).doc(id).delete()
)

export const subscribeCategory = ({ categoryName, setData }) => {
  return db.collection(categoryName).orderBy('lastModified', 'desc').onSnapshot((snapshot) => {
    const data = []
    snapshot.forEach((doc) => {
      data.push({
        ...doc.data(),
        id: doc.id,
      })
    })
    setData(data)
    console.log('dataUpdated')
  })
}

export const subscribeOwnerCategory = ({ categoryName, setData, owner }) => {
  return db.collection(categoryName)
    .orderBy('lastModified', 'desc')
    .onSnapshot((snapshot) => {
      const data = []
      snapshot.forEach((doc) => {
        if (doc.data().owner === owner) {
          data.push({
            ...doc.data(),
            id: doc.id,
          })
        }
      })
      setData(data)
    })
}
