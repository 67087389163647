import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { secondsToString } from 'shared/time'
import './style.scss'

export default function Timer({ secondsProps, stopCallback }) {
  const [isActive, setActive] = useState(false)
  const [time, setTime] = useState(0)
  const [prepareTime, setPrepareTime] = useState(3)
  const [prepareIsShowing, setShowingPrepare] = useState(false)

  let intervalTimer

  useEffect(() => {
    let interval
    if (isActive) {
      setTime(0)
      interval = setInterval(
        () => setTime((prevElapsedTime) => prevElapsedTime + 1),
        1000,
      )
    }
    return () => clearInterval(interval)
  }, [isActive])

  useEffect(() => {
    clearInterval(intervalTimer)
    setTime(secondsProps)
  }, [secondsProps, intervalTimer])

  const runTimer = () => {
    // eslint-disable-next-line prefer-const
    intervalTimer = setInterval(
      () => setPrepareTime((prevElapsedTime) => {
        if (prevElapsedTime < 2) {
          setActive(true)
          clearInterval(intervalTimer)
          setShowingPrepare(false)
        }
        return prevElapsedTime - 1
      }),
      1000,
    )
    return () => clearInterval(intervalTimer)
  }

  const stopTimer = () => {
    setPrepareTime(3)
    setActive(false)
    stopCallback(time)
  }

  const startTimer = () => {
    setShowingPrepare(true)
    return runTimer()
  }

  return (
    <div className="timer">
      <div className="timer__time">{secondsToString({ seconds: time })}</div>
      <div className="timer__hover">
        <button
          type="button"
          className="timer__button"
          onClick={isActive ? stopTimer : startTimer}
        >
          {isActive ? 'Stop' : 'Start'}
        </button>
      </div>
      {prepareIsShowing && (
        <div className="timer__prepare">
          <div className="timer__prepare-content">
            <div className="timer__prepare-count">{prepareTime}</div>
          </div>
        </div>
      )}
    </div>
  )
}

Timer.propTypes = {
  secondsProps: PropTypes.number.isRequired,
  stopCallback: PropTypes.func.isRequired,
}
