import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

export const SearchContext = React.createContext({})

export const SearchProvider = ({ children = null }) => {
  const [searchText, setSearchText] = useState(null)

  useEffect(() => {
  }, [])


  const setText = (data) => {
    setSearchText(data)
  }

  const contextValue = {
    text: searchText,
    setText,
  }

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  )
}

SearchProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}
