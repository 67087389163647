export const getWordsCount = ({ string }) => {
  const regex = /(?:\r\n|\r|\n)/g
  const cleanString = string.replace(regex, ' ').trim()
  const wordArray = cleanString.match(/\S+/g)
  return wordArray ? wordArray.length : 0
}

export const stripHtml = (string) => {
  const newString = string.replace(/<[^>]*>?/gm, '')
  return newString.replace('&nbsp;', '')
}
