import React from 'react'
import { renderBlock } from './render'

export const BlocksList = ({
  blocks,
  setBlockData,
  setBlocks,
  category,
  createBlock,
  updateTimeCoef,
  timeCoef,
}) => {
  const removeBlock = ({ currentIndex }) => {
    const newData = []

    blocks.map((currElement, index) => {
      if (currentIndex !== index) {
        newData.push(currElement)
      }
      return null
    })

    const newBlocks = [...newData]
    setBlocks([...newBlocks])
  }

  const goUpBlock = ({ currentIndex }) => {
    const current = []
    const previous = []
    const next = []

    blocks.map((currElement, index) => {
      if (index <= (currentIndex - 2)) {
        previous.push(currElement)
      // eslint-disable-next-line no-tabs
      }	else if (index === currentIndex) {
        current.push(currElement)
      } else if (index >= (currentIndex - 2) && (index !== currentIndex)) {
        next.push(currElement)
      } return null
    })

    const newBlocks = [...previous, ...current, ...next]
    setBlocks([...newBlocks])
    return null
  }

  const goDownBlock = ({ currentIndex }) => {
    const current = []
    const previous = []
    const next = []

    blocks.map((currElement, index) => {
      if (index <= (currentIndex + 1) && index !== currentIndex) {
        previous.push(currElement)
      // eslint-disable-next-line no-tabs
      }	else if (index === currentIndex) {
        current.push(currElement)
      } else if (index > (currentIndex + 1)) {
        next.push(currElement)
      } return null
    })

    const newBlocks = [...previous, ...current, ...next]

    setBlocks([...newBlocks])
  }

  // eslint-disable-next-line react/prop-types
  return (blocks.length > 0) ? blocks.map((block, index) => renderBlock({
    type: block.type,
    index,
    block,
    category,
    setBlockData,
    goUpBlock,
    goDownBlock,
    removeBlock,
    createBlock,
    updateTimeCoef,
    timeCoef,
  })) : <div />
}
