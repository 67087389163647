import * as firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const config = {
  apiKey: 'AIzaSyDoSynBjuAuOK00_d8LwKWt0JX0SLD_8i4',
  authDomain: 'fsn-scripts.firebaseapp.com',
  databaseURL: 'https://fsn-scripts.firebaseio.com',
  projectId: 'fsn-scripts',
  storageBucket: 'fsn-scripts.appspot.com',
  messagingSenderId: '301806403031',
  appId: '1:301806403031:web:72efd9de625ced5bf51f92',
  measurementId: 'G-44VH45XVM1',
}

const app = firebase.initializeApp(config)

export default app
