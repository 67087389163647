import React, { useContext, useState, useEffect } from 'react'
import firebase from 'firebase'
import { withRouter, useParams } from 'react-router'
import { useSnackbar } from 'material-ui-snackbar-provider'

import { ApplicationContext } from 'context'
import classNames from 'classnames'
import { saveDoc } from 'shared/firebase'
import { CREATE_DEFAULT_DATA, DEFAULT_BLOCKS_DATA } from '../const'

import { renderDocument } from '../components/document'

import Header from '../components/header'
import css from './create.module.scss'

function CreateScriptPage({ history }) {
  const [documentData, setDocument] = useState({})
  const [blocks, setBlocks] = useState([])
  const [titleError, setTitleError] = useState(null)

  const { category: categoryParams } = useParams()
  const {
    user,
    activeCategory: {
      setCategory,
    },
  } = useContext(ApplicationContext)

  const snackbar = useSnackbar()

  useEffect(() => {
    if (documentData.title) {
      setTitleError(null)
    }
  }, [(documentData)])

  useEffect(() => {
    setCategory(categoryParams)
    setDocument(CREATE_DEFAULT_DATA[categoryParams])
    setBlocks(DEFAULT_BLOCKS_DATA[categoryParams])
  }, [categoryParams, setCategory])

  const showAlert = () => {
    snackbar.showMessage(
      'Document Saved',
      null,
      null,
      { type: 'success' },
    )
  }

  const createAndSave = () => {
    if (documentData.title) {
      const currentDate = new Date()
      const timestamp = firebase.firestore.Timestamp.fromDate(currentDate)

      const document = {
        ...documentData,
        owner: user.email,
        dateCreated: timestamp,
        lastEditedBy: user.email,
        lastModified: timestamp,
        blocks: [...blocks],
      }
      delete document.createdAt

      saveDoc({
        collectionName: categoryParams, saveCallBack: () => showAlert(), history, document,
      })
    } else {
      setTitleError('Title is required')
    }
  }

  return (
    <div className={css.page}>
      <Header
        userEmail={user ? user.email : ''}
        saveDocument={createAndSave}
      />
      <main className={classNames(css.page__main)}>
        <div className={classNames(css.form)}>
          {renderDocument({
            scriptCategory: categoryParams,
            documentData,
            blocks,
            setDocument,
            setBlocks,
            titleError,
          })}
        </div>
      </main>
    </div>
  )
}

export default withRouter(CreateScriptPage)
