import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Grid,
} from '@material-ui/core'

import FootageHeader from './header'

import { DEFAULT_VIDEO, DEFAULT_AUDIO } from '../../../const'
import { BlocksList } from '../render-blocks'

import css from './footage.module.scss'

function FootageDocument({
  documentData,
  setDocument,
  titleError,
  blocks,
  setBlocks,
}) {
  const {
    submitted,
    title,
    framerate,
  } = documentData

  const changeTitle = (event) => {
    setDocument({ ...documentData, title: event.target.value })
  }

  const changeFramerate = (event) => {
    setDocument({ ...documentData, framerate: event.target.value })
  }

  const setBlockData = ({ index, data }) => {
    const newBlockData = { ...blocks[index], ...data }
    const allBlocks = [...blocks]
    allBlocks.splice(index, 1, newBlockData)
    setBlocks([...allBlocks])
  }

  const createBlock = ({ type = 'video' }) => {
    if (type === 'video') {
      const newBlockData = [...blocks, { ...DEFAULT_VIDEO }]
      setBlocks([...newBlockData])
    } if (type === 'audio') {
      const newBlockData = [...blocks, { ...DEFAULT_AUDIO }]
      setBlocks([...newBlockData])
    }
  }

  return (
    <>
      <div className={css.form__head}>
        <Container>
          <FootageHeader
            changeTitle={changeTitle}
            title={title}
            titleError={titleError}
            framerate={framerate}
            changeFramerate={changeFramerate}
            submitted={submitted}
            createBlock={createBlock}
          />
        </Container>
      </div>
      <div className={css.form__main}>
        <div className={css['form__scroll-wrapper']}>
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <BlocksList
                  blocks={blocks}
                  setBlockData={setBlockData}
                  setBlocks={setBlocks}
                  category="footage"
                />
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  )
}

FootageDocument.propTypes = {
  documentData: PropTypes.objectOf(PropTypes.any).isRequired,
  setDocument: PropTypes.func.isRequired,
  titleError: PropTypes.bool.isRequired,
  blocks: PropTypes.objectOf(PropTypes.any).isRequired,
  setBlocks: PropTypes.func.isRequired,
}

export default FootageDocument

