import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'

import { ApplicationContext } from 'context'
import { subscribeCategory, subscribeOwnerCategory } from 'shared/firebase'
import Spinner from 'components/spinner'
import ScriptsList from './scripts-list'

const ListWrapper = ({ categoryName }) => {
  const [isLoading, setLoading] = useState(true)
  const {
    user,
    collection: { data: scriptsContext, setActiveCollection },
  } = useContext(ApplicationContext)

  const setter = (collection) => {
    const coll = collection
    setActiveCollection(coll)
    setTimeout(() => {
      setLoading(false)
    }, 200)
  }

  useEffect(() => {
    let unsubscribe
    if (user !== null) {
      // SetTimeot Just for Better UI exp
      unsubscribe = user.editor
        ? subscribeCategory({ categoryName, setData: (collection) => setter(collection) })
        : subscribeOwnerCategory({ categoryName, setData: (collection) => setter(collection), owner: user.email })
    } return unsubscribe
  }, [user, categoryName, setActiveCollection])

  return (
    <div>
      {isLoading ? <Spinner /> : <ScriptsList scripts={scriptsContext} categoryName={categoryName} />}
    </div>
  )
}

ListWrapper.propTypes = {
  categoryName: PropTypes.string.isRequired,
}

export default ListWrapper
