import React, { useState, useEffect } from 'react'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import classNames from 'classnames'
import htmlToDraft from 'html-to-draftjs'
import { Editor } from 'react-draft-wysiwyg'
import { getWordsCount } from 'shared/strings'

import Spinner from 'components/spinner'

import { DEFAULT_CONFIG } from 'text-editor/config'
import { DEFAULT_STATE } from './const'

const DraftJS = ({ getHtml, defaultHtml, setWordsCount, placeholder = '' }) => {
  const [isFocused, setFocused] = useState(false)
  const [isSaving, setSaving] = useState(false)
  const [isEmpty, setEmpty] = useState(false)
  const [editorState, editorStateChange] = useState(null)
  const [htmlString, setHtmlString] = useState(DEFAULT_STATE)

  const getContentFromHtml = (html) => {
    const blocksFromHtml = htmlToDraft(html)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
    return contentState
  }

  const setDefaulState = () => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!defaultHtml) {
      const contentState = getContentFromHtml(defaultHtml)
      const htmlState = EditorState.createWithContent(contentState)
      setHtmlString(`${defaultHtml}\n`)
      editorStateChange(htmlState)
    } else {
      const emptyState = EditorState.createEmpty()
      editorStateChange(emptyState)
    }
  }

  useEffect(() => {
    setDefaulState()

    if (!defaultHtml) {
      setEmpty(true)
    }
  }, [])


  const onEditorStateChange = (state) => {
    editorStateChange(state)
    const count = getWordsCount({ string: editorState.getCurrentContent().getPlainText('') })
    setWordsCount && setWordsCount(count)
  }

  const toHtml = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    const markup = draftToHtml(
      rawContentState,
    )
    return markup
  }

  const onFocus = () => {
    setFocused(true)
    setEmpty(false)
  }

  const isHtmlChanged = (prev, current) => prev !== current

  const getAndSave = (html) => {
    setSaving(true)
    getHtml(html)
    setHtmlString(html)
    setTimeout(() => {
      setSaving(false)
    }, 1000)
  }

  const onBlur = () => {
    setFocused(false)
    const htmlFromDraft = toHtml()
    const isChanged = isHtmlChanged(htmlString, htmlFromDraft)

    // eslint-disable-next-line no-unused-expressions
    isChanged ? getAndSave(htmlFromDraft) : setHtmlString(htmlFromDraft)
    if (htmlFromDraft === DEFAULT_STATE) {
      setEmpty(true)
    }
  }

  return (
    <div className="draft-js">
      <Editor
        toolbar={DEFAULT_CONFIG}
        editorState={editorState}
        wrapperClassName={classNames(
          'toolbar__wrapper',
          isFocused && 'toolbar__wrapper--show',
          isEmpty && 'toolbar__wrapper--empty',
        )}
        onFocus={onFocus}
        onBlur={onBlur}
        onEditorStateChange={onEditorStateChange}
      />
      {isSaving && (
      <div className="draft-js__spinner">
        <Spinner />
      </div>
      )}
      {!isSaving && isEmpty && (
        <div className="draft-js__placeholder">{placeholder}</div>
      )}
    </div>
  )
}

export default DraftJS
