import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LocationSelectInput from 'components/location'
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  Menu,
  MenuItem,
  Select,
  InputLabel,
} from '@material-ui/core'

import MenuIcon from '@material-ui/icons/Menu'

import { getDateFromUnix } from 'shared/date'

import firebase from 'firebase'

import DateFnsUtils from '@date-io/date-fns'

import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'

import './card.scss'

import { debounce } from 'lodash'

const LANGUAGES = [
  { text: 'English', value: 'en-US' },
  { text: 'Russian', value: 'ru-RU' },
  { text: 'Ukrainian', value: 'uk-UA' },
  { text: 'Japanese', value: 'ja-JP' },
  { text: 'French', value: 'fr-FR' },
  { text: 'Mandarin', value: 'zh-CN' },
  { text: 'German', value: 'de-DE' },
  { text: 'Spanish', value: 'es-ES' },
  { text: 'Arabic', value: 'ar-YE' },
  { text: 'Hindi', value: 'hi-IN' },
  { text: 'Latvian', value: 'lv-LV' },
  { text: 'Amharic', value: 'am-ET' },
  { text: 'Hungarian', value: 'hu-HU' },
  { text: 'Turkish', value: 'tr-TR' },
  { text: 'Swahili', value: 'sw-TZ' },
]

const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(1),
    display: 'flex',
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    },
  },
  language: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: '8px',
    height: '100%',
  },
  label: {
    fontSize: '12px',
  },
}))

function AudioCard({
  data,
  setData,
  index,
  goUpBlock,
  goDownBlock,
  removeBlock,
}) {
  const classes = useStyles()
  /*eslint-disable */
  const {
    accessRestriction,
    acquisition,
    description,
    in: timeIn,
    language,
    location,
    name,
    out: timeOut,
    source,
    speechToText,
    title,
    objectRecognition,
    celebrityDetection,
    sound2text,
    faceDetection,
  } = data

  const [anchorEl, setAnchorEl] = useState(null)

  const [testTitle, changeTitle] = useState('')

  const changeTest = debounce(() => setData({ index, data: { title: testTitle }}), 400)

  const testChangeTitle = (event) => {
    const { id, value, name: nameTarget } = event.target
    changeTitle(value)
  }

  useEffect(() => {
    changeTest()
  }, [testTitle])

   /* eslint-enable */
  const getLocation = (place) => {
    setData({ index, data: { location: place.place, coordinates: place.coordinates } })
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const upAndClose = () => {
    goUpBlock()
    handleClose()
  }

  const downAndClose = () => {
    goDownBlock()
    handleClose()
  }

  const removeAndClose = () => {
    removeBlock()
    handleClose()
  }

  const changeField = (event) => {
    const { id, value, name: nameTarget } = event.target
    if (nameTarget && (nameTarget === 'language')) {
      setData({ index, data: { [nameTarget]: value } })
    } else if (id === 'objectRecognition') {
      setData({ index, data: { [id]: !objectRecognition } })
    } else if (id === 'celebrityDetection') {
      setData({ index, data: { [id]: !celebrityDetection } })
    } else if (id === 'sound2text') {
      setData({ index, data: { [id]: !sound2text } })
    } else if (id === 'faceDetection') {
      setData({ index, data: { [id]: !faceDetection } })
    } else {
      setData({ index, data: { [id]: value } })
    }
  }

  const changeDate = (value) => {
    const timestamp = firebase.firestore.Timestamp.fromDate(value)
    setData({ index, data: { acquisition: timestamp } })
  }

  return (
    <div className="card">
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.box}>
            <span className="card__label">SOT</span>
            <div>
              <Button className="card__menu" variant="contained" color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <MenuIcon />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={upAndClose}>Move Up</MenuItem>
                <MenuItem onClick={downAndClose}>Move Down</MenuItem>
                <MenuItem onClick={removeAndClose}>Remove</MenuItem>
              </Menu>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.box}>
            <TextField id="title" label="Interviewee Title / Organizational Affiliation" fullWidth value={testTitle} onChange={testChangeTitle} />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={3}>
          <div className={classes.box}>
            <TextField id="name" label="Name" fullWidth value={name} onChange={changeField} />
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className={classes.box}>
            <TextField
              id="source"
              label="Source"
              fullWidth
              value={source}
              onChange={changeField}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={2}>
          <div className={classes.box}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Date Shot"
                value={acquisition && getDateFromUnix(acquisition)}
                onChange={changeDate}
              />
            </MuiPickersUtilsProvider>
          </div>
        </Grid>
        <Grid item xs={12} sm={2}>
          <div className={classes.box}>
            <FormControlLabel
              control={(
                <Checkbox
                  id="objectRecognition"
                  checked={objectRecognition}
                  color="primary"
                  name="speechToText"
                  className="card__checkbox"
                  onChange={changeField}
                />
                )}
              label="Object Recognition"
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={2}>
          <div className={classes.box}>
            <FormControlLabel
              control={(
                <Checkbox
                  id="celebrityDetection"
                  checked={celebrityDetection}
                  onChange={changeField}
                  color="primary"
                  className="card__checkbox"
                  name="Celebrity Detection"
                />
              )}
              label="Celebrity Detection"
            />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={3}>
          <div className={classes.box}>
            <TextField
              id="accessRestriction"
              label="Access restriction"
              fullWidth
              value={accessRestriction}
              onChange={changeField}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className={classes.box}>
            <LocationSelectInput
              location={location}
              getLocation={getLocation}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={2}>
          <div className={classes.language}>
            <InputLabel className={classes.label}>Language</InputLabel>
            <Select
              id="language"
              label="Language"
              name="language"
              value={language}
              onChange={changeField}
            >
              <MenuItem value="en-US">English</MenuItem>
              {LANGUAGES.map(({ text, value }) => (
                <MenuItem value={value}>{text}</MenuItem>
              ))}
            </Select>
          </div>
        </Grid>
        <Grid item xs={12} sm={2}>
          <div className={classes.box}>
            <FormControlLabel
              control={(
                <Checkbox
                  id="sound2text"
                  checked={sound2text}
                  onChange={changeField}
                  color="primary"
                  className="card__checkbox"
                  name="Sound To Text"
                />
              )}
              label="Sound To Text"
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={2}>
          <div className={classes.box}>
            <FormControlLabel
              control={(
                <Checkbox
                  id="faceDetection"
                  checked={faceDetection}
                  onChange={changeField}
                  color="primary"
                  name="Face Detection"
                  className="card__checkbox"
                />
              )}
              label="Face Detection"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default AudioCard
