import React, { useState, useEffect } from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import classNames from 'classnames'
import {
  TextField,
} from '@material-ui/core'
import './style.scss'
import { isEmpty } from 'lodash'

const LocationSearchInput = ({ location, getLocation }) => {
  const [address, setAddress] = useState('')

  const handleChange = (place) => {
    if (address) {
      setAddress(null)
    }
    setAddress(place)
  }

  const handleSelect = (place) => {
    let coordinates = []
    geocodeByAddress(place)
      // eslint-disable-next-line no-return-assign
      .then((results) => getLatLng(results[0]).then((result) => coordinates = result))
      .then(() => getLocation({ place, coordinates }))
      .catch((error) => console.error('Error', error))
  }

  useEffect(() => {
    if (location) {
      setAddress(location)
    }
  }, [location])

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({
        getInputProps, suggestions, getSuggestionItemProps, loading,
      }) => (
        <div className="autocomplete">
          <TextField
            id="search"
            label="Location"
            fullWidth
            className="autocomplete__input"
            value={address}
            {...getInputProps()}
          />
          <div className="autocomplete__dropdown">
            <div className={classNames('autocomplete__container', (loading || !isEmpty(suggestions)) && 'autocomplete__container--ready')}>
              {loading && <div className="autocomplete__item">Loading...</div>}
              {suggestions.map((suggestion) => {
                const props = getSuggestionItemProps(suggestion)
                return (
                  <div className="autocomplete__item" {...props}>
                    <div className="autocomplete__city">{suggestion.formattedSuggestion.mainText}</div>
                    <div>{suggestion.formattedSuggestion.secondaryText}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default LocationSearchInput
