import React from 'react'
import PropTypes from 'prop-types'
import { ListItem as Item } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import classNames from 'classnames'
import css from './preview.module.scss'

const TvScriptsPreview = ({
  isActive, itemId, categoryName, createdAt, title, owner, setActive, client, reviewed,
}) => (
  <Item
    selected={isActive}
    className={classNames(css.item, css['item--hover'])}
    onClick={() => setActive({ id: isActive ? '' : itemId, category: categoryName })}
  >
    <span className={css.item__date}>
      <span className="text-elipsis">
        {createdAt}
      </span>
    </span>
    <span className={classNames(css.item__title, css['item__title--short'])}>
      <span className="text-elipsis">
        {title}
      </span>
    </span>
    <span className={css.item__client}>{client}</span>
    <span className={classNames(css.item__owner, css['item__owner--short'])}>{owner}</span>
    <span className={css.item__icon}>
      {!reviewed && (
        <VisibilityIcon color="primary" />
      )}
    </span>

  </Item>
)

TvScriptsPreview.propTypes = {
  isActive: PropTypes.bool.isRequired,
  itemId: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
  client: PropTypes.string.isRequired,
  reviewed: PropTypes.bool.isRequired,
}

export default TvScriptsPreview
