import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  tabs: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.info.dark,
  },
  tab: {
    minWidth: '130px',
  },
}))
