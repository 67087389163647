export const DEFAULT_CONFIG = {
  options: ['inline', 'fontSize', 'colorPicker', 'remove'],
  inline: {
    inDropdown: false,
    className: 'toolbar',
    dropdownClassName: 'toolbar-dropdown',
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
  colorPicker: {
    colors: ['#f44336', '#9c27b0', '#673ab7', '#03a9f4',
      '#ffeb3b', '#4caf50'],
  },
}
