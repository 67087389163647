// import { useWhatChanged } from "@simbathesailor/use-what-changed"
import React from 'react'
import { SnackbarProvider } from 'material-ui-snackbar-provider'
import { Snackbar } from '@material-ui/core'
import Alert from 'components/alert'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import { routes } from './routes'
import PrivateRoute from './private'
import { HomePage } from '../pages'
import { ApplicationProvider } from '../context'

function App() {
  function SnackbarComponent(props) {
    const {
      // eslint-disable-next-line react/prop-types
      message, customParameters, SnackbarProps,
    } = props
    return (
      <Snackbar
        {...SnackbarProps}
        message={message}
      >
        <Alert severity={(customParameters && customParameters.type) || 'info'}>
          {message}
        </Alert>
      </Snackbar>
    )
  }


  return (
    <ApplicationProvider>
      <SnackbarProvider SnackbarProps={{ autoHideDuration: 3000 }} SnackbarComponent={SnackbarComponent}>
        <Router>
          <Switch>
            <PrivateRoute exact path="/" component={HomePage} />
            {routes.map(({ path, name, component }) => (
              <Route
                component={component}
                path={path}
                key={name}
                exact
              />
            ))}
            <Route path="*">
              <div>No match Page</div>
            </Route>
          </Switch>
        </Router>
      </SnackbarProvider>
    </ApplicationProvider>
  )
}

export default App
