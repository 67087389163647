import React from 'react'

import {
  TextField,
  Grid,
  Button,
} from '@material-ui/core'

import css from './footage.module.scss'

const FootageHeader = React.memo(({
  /* eslint-disable */
  changeTitle,
  title,
  titleError,
  framerate,
  changeFramerate,
  submitted,
  createBlock,
}) => (
  /* eslint-enable */
  <Grid container>
    <Grid item xs={5}>
      <div className={css.form__header}>
        <TextField
          id="title"
          label="Title"
          className={css['form__header-input']}
          onChange={changeTitle}
          value={title || ''}
          required
          error={!!titleError}
          helperText={titleError || null}
        />
      </div>
    </Grid>
    <Grid item xs={3}>
      <div className={css['form__header-title']}>
        {submitted ? 'SUBMITTED' : 'NOT SUBMITTED'}
      </div>
    </Grid>
    <Grid item xs={4}>
      <div className={css.wrap}>
        <Button variant="contained" color="primary" onClick={() => createBlock({ type: 'video' })} className={css['mr-4']}>Add Video</Button>
        <Button variant="contained" color="primary" onClick={() => createBlock({ type: 'audio' })}>Add SOT</Button>
      </div>
    </Grid>
  </Grid>
))

export default FootageHeader
