import React from 'react'
import PropTypes from 'prop-types'
import { ListItem as Item } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import classNames from 'classnames'
import css from './preview.module.scss'

const FootagePreview = ({
  isActive, itemId, categoryName, createdAt, title, owner, setActive,
}) => (
  <Item
    selected={isActive}
    className={classNames(css.item)}
    onClick={() => setActive({ id: isActive ? '' : itemId, category: categoryName })}
  >
    <span className={css.item__date}>
      <span className="text-elipsis">
        {createdAt}
      </span>
    </span>
    <span className={css.item__title}>
      <span className="text-elipsis">
        {title}
      </span>
    </span>
    <span className={css.item__owner}>
      <span className="text-elipsis">
        {owner}
      </span>
    </span>
    <span className={css.item__icon}>
      {isActive && <VisibilityIcon />}
    </span>
  </Item>
)

FootagePreview.propTypes = {
  isActive: PropTypes.bool.isRequired,
  itemId: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
}

export default FootagePreview
