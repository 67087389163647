import React, { useContext, useState, useEffect } from 'react'
import { ApplicationContext } from 'context'
import { TVSCRIPTS_COLLECTION } from 'const'
import { useSnackbar } from 'material-ui-snackbar-provider'
import { omit, get } from 'lodash'

import {
  useParams,
  withRouter,
} from 'react-router-dom'
import firebase from 'firebase'
import classNames from 'classnames'
import { updateDoc } from 'shared/firebase'

import { renderDocument } from '../components/document'

import Header from '../components/header'

import css from './edit.module.scss'

function ScriptPage({ history }) {
  const [documentData, setDocument] = useState({})
  const [blocks, setBlocks] = useState([])
  const [cached, setCached] = useState({ document: '', blocksDocument: ''})
  const snackbar = useSnackbar()

  const [titleError, setTitleError] = useState(null)
  const {
    user,
    activeCategory: {
      setCategory,
    },
  } = useContext(ApplicationContext)

  const { category: categoryParams, documentId } = useParams()

  useEffect(() => {
    const db = firebase.firestore()
    const docRef = db.collection(categoryParams).doc(documentId)
    setCategory(categoryParams)
    docRef.get().then((doc) => {
      if (doc.exists) {
        const data = doc.data()
        const document = omit(data, ['blocks'])
        const blocksDocument = get(data, 'blocks')
        if (categoryParams === TVSCRIPTS_COLLECTION) {
          setCached({ document, blocksDocument })
        }
        setDocument(document)
        setBlocks(blocksDocument)
      } else {
        history.push('/')
      }
    }).catch((error) => {
      console.log('Error getting document:', error)
    })
  }, [])


  useEffect(() => {
    if (documentData.title) {
      setTitleError(null)
    }
  }, [documentData.title])

  const revertChanges = () => {
    const { document, blocksDocument } = cached
    setDocument({ ...document })
    setBlocks([...blocksDocument])
  }

  const showAlert = () => {
    snackbar.showMessage(
      'Document Updated',
      null,
      null,
      { type: 'success' },
    )
  }

  const updateDocument = () => {
    if (documentData.title) {
      const currentDate = new Date()
      const timestamp = firebase.firestore.Timestamp.fromDate(currentDate)

      const document = {
        ...documentData,
        lastEditedBy: user.email,
        lastModified: timestamp,
        blocks: [...blocks],
      }

      delete document.createdAt

      updateDoc({
        collectionName: categoryParams, updateCallback: showAlert, documentId, document, history,
      })
    } else {
      setTitleError('Title is required')
    }
  }

  return (
    <div className={css.page}>
      <Header
        userEmail={!!user && user.email}
        saveDocument={updateDocument}
        discardChanges={revertChanges}
      />
      <main className={css.page__main}>
        <div className={classNames(css.form)}>
          {renderDocument({
            scriptCategory: categoryParams,
            documentData,
            blocks,
            setDocument,
            setBlocks,
            titleError,
          })}
        </div>
      </main>
    </div>
  )
}

export default withRouter(ScriptPage)
