import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import Clock from 'react-live-clock'

import {
  Container,
  Grid,
  AppBar,
  Box,
  Button,
} from '@material-ui/core'

import styles from './header.module.scss'

function Header({
  userEmail, history, saveDocument, discardChanges,
}) {
  const [date, setDate] = useState(null)

  useEffect(() => {
    const currentDate = Date.now()
    setDate(currentDate)
  }, [])


  const backToHome = () => {
    // eslint-disable-next-line react/prop-types
    history.push('/login')
  }

  return (
    <header>
      <AppBar position="static" className={styles.header}>
        <Container>
          <Grid container>
            <Grid item xs={3}>
              <Button
                className={styles.header__button}
                onClick={backToHome}
              >
                Back
              </Button>
              <Button className={styles.header__button} onClick={saveDocument}>Save</Button>
              {/* <Button className={styles.header__button} onClick={discardChanges}>Revert</Button> */}
            </Grid>
            <Grid item xs={3}>
              <span>
                {'GTM '}
                <Clock date={date} format="HH:mm YYYY-MM-DD" ticking interval={100000} timezone="GMT" />
              </span>
            </Grid>
            <Grid item xs={3}>
              <span>
                {'LOCAL '}
                <Clock date={date} format="HH:mm YYYY-MM-DD" ticking interval={100000} />
              </span>
            </Grid>
            <Grid item xs={3}>
              <Box fontWeight={300} textAlign="right">
                {userEmail || ''}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
    </header>
  )
}

Header.propTypes = {
  userEmail: PropTypes.string,
  discardChanges: PropTypes.func,
}

Header.defaultProps = {
  userEmail: null,
  discardChanges: null,
}

export default withRouter(Header)
