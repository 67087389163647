import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import classNames from 'classnames'
import { ApplicationContext } from 'context'
import { getModifiedDate, getDateToJsonFromUnix, getNumberDateFromUnix } from 'shared/date'
import { updateDoc, removeDoc } from 'shared/firebase'
import { useSnackbar } from 'material-ui-snackbar-provider'
import TelegramIcon from '@material-ui/icons/Telegram'
import { withRouter } from 'react-router'
import VisibilityIcon from '@material-ui/icons/Visibility'
import firebase from 'firebase'
import EditIcon from '@material-ui/icons/Edit'
import LockIcon from '@material-ui/icons/Lock'
import LinearProgress from '@material-ui/core/LinearProgress'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

import {
  Button,
} from '@material-ui/core'

import css from './preview.module.scss'
import Block from './block'

function Preview({ history }) {
  const [script, setScript] = useState(null)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [sendInProgress, setSendInProgress] = useState(false)
  const { activeScript, collection: { data }, user } = useContext(ApplicationContext)

  const getActiveScript = () => data.find(({ id }) => id === activeScript.active.id)

  const snackbar = useSnackbar()
  useEffect(() => {
    if (activeScript.active && data) {
      const active = getActiveScript()
      setScript(active)
    } else {
      setScript(null)
    }
  }, [activeScript.active, data, getActiveScript])

  const goToEditPage = () => {
    history.push(`/script/${activeScript.active.category}/${activeScript.active.id}`)
  }

  const removeDocument = () => {
    removeDoc({ collectionName: activeScript.active.category, id: activeScript.active.id })
    snackbar.showMessage(
      'Document Removed',
      null,
      null,
      { type: 'warning' },
    )
  }

  const fileSentToAWS = () => {
    snackbar.showMessage(
      'Starting File Upload',
      null,
      null,
      { type: 'success' },
    )
  }

  const submitDocument = () => {
    const document = {
      ...script,
      submitted: true,
      locked: true,
    }
    updateDoc({ collectionName: activeScript.active.category, documentId: activeScript.active.id, document })
  }


  const toggleReviewDoc = () => {
    const document = {
      ...script,
      reviewed: !script.reviewed,
    }
    updateDoc({ collectionName: activeScript.active.category, documentId: activeScript.active.id, document })
  }

  const showAlertAndLock = () => {
    fileSentToAWS()
    submitDocument()
  }

  const onChangeUploadFile = (event) => {
    const activeDocument = getActiveScript()
    const [firstBlock] = activeDocument.blocks
    const isSOT = firstBlock.type === 'sot'

    const newData = isSOT ? {
      metadata: {
        formData: {
          default_title: activeDocument.title || '',
          default_description: firstBlock.description || '',
          default_mediaCreatedOn: firstBlock.acquisition ? getDateToJsonFromUnix(firstBlock.acquisition) : '',
          contributorEmail: activeDocument.owner || '',
          source: firstBlock.source || '',
          accessRestrictions: firstBlock.accessRestriction || '',
          location: firstBlock.location,
          framerate: activeDocument.framerate || 25,
          fullname: firstBlock.name,
          credentials: firstBlock.title,
        },
        formId: 'SOT',
      },
      coordinates: [
        {
          lat: (firstBlock && firstBlock.coordinates) ? firstBlock.coordinates.lat : '',
          lng: (firstBlock && firstBlock.coordinates) ? firstBlock.coordinates.lng : '',
        },
      ],
      analysis: {
        personDetectionEnabled: firstBlock.faceDetection,
        labelDetectionEnabled: firstBlock.objectRecognition,
        celebrityDetectionEnabled: firstBlock.celebrityDetection,
        transcriptionEnabled: firstBlock.sound2text,
        transcriptionService: 'speechmaticsSpeechToText',
        languageCode: (firstBlock.language ? firstBlock.language : 'en'),
        transcriptionChannels: [0, 1],
      },
    } : {
      metadata: {
        formData: {
          default_title: activeDocument.title || '',
          default_description: firstBlock.description || '',
          default_mediaCreatedOn: firstBlock.acquisition ? getDateToJsonFromUnix(firstBlock.acquisition) : '',
          contributorEmail: activeDocument.owner || '',
          source: firstBlock.source || '',
          accessRestrictions: firstBlock.accessRestriction || '',
          location: firstBlock.location,
          framerate: activeDocument.framerate || 25,
        },
        formId: 'default',
      },
      coordinates: [
        {
          lat: (firstBlock && firstBlock.coordinates) ? firstBlock.coordinates.lat : '',
          lng: (firstBlock && firstBlock.coordinates) ? firstBlock.coordinates.lng : '',
        },
      ],
      analysis: {
        personDetectionEnabled: firstBlock.faceDetection,
        labelDetectionEnabled: firstBlock.objectRecognition,
        celebrityDetectionEnabled: firstBlock.celebrityDetection,
        transcriptionEnabled: firstBlock.sound2text,
      },
    }


    const file = event.target.files[0]
    const { name } = file
    const jsonString = JSON.stringify(newData)
    const splitName = name.split('.')
    const blob = new Blob([jsonString], { type: 'application/json' })

    const sendFile = (signedData, userFile, fileName) => {
      const formData = new FormData()
      Object.keys(signedData.data.fields).forEach((key) => {
        formData.append(key, signedData.data.fields[key])
      })

      formData.append('file', userFile)
      setSendInProgress(true)
      const config = {
        onUploadProgress: (progressEvent) => {
          setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        },
      }
      axios.post(signedData.data.url, formData, config)
        .then((res) => {
          // eslint-disable-next-line no-unused-expressions
          console.log('Upload File', res)
        })
        .catch((error) => {
          console.error('Upload File', error)
        })
        .finally(() => {
          if (!fileName.includes('json')) {
            setSendInProgress(false)
            setUploadProgress(0)
          }
        })
    }

    const preSignAndSent = (fileName, userFile, showAlert = null) => {
      axios.post('https://b8fa3or1ud.execute-api.eu-west-1.amazonaws.com/createurl', { name: fileName })
        .then((res) => {
          const resData = JSON.parse(res.data.body)
          return resData
        })
        .then((resData) => {
          sendFile(resData, userFile, fileName)

          // eslint-disable-next-line no-unused-expressions
          showAlert && showAlert(true)
        })
        .catch((error) => {
          // eslint-disable-next-line no-unused-expressions
          console.error('Presigned', error)
        })
    }

    preSignAndSent(name, file)
    preSignAndSent(`${splitName[0]}.json`, blob, showAlertAndLock)
  }

  const lockDocument = () => {
    const db = firebase.firestore()
    const currentDate = new Date()
    const timestamp = firebase.firestore.Timestamp.fromDate(currentDate)
    const document = {
      ...script,
      lastEditedBy: user.email,
      lastModified: timestamp,
      locked: !script.locked,
    }
    db.collection('footage').doc(activeScript.active.id).update({ ...document })
      .then(() => {
        console.log('documentUpdated')
      })
      .catch((error) => {
        console.error('Error adding document: ', error)
      })
  }

  const isTvScripts = activeScript.active.category === 'tvscripts'

  return script ? (
    <div className={css.preview}>
      <header className={css.preview__header}>
        <span className={css.preview__item}>
          Created:
          {' '}
          {getNumberDateFromUnix(script.dateCreated)}
          {' '}
          by
          {' '}
          {script.owner}
        </span>
        {script.lastModified && (
        <span className={css.preview__item}>
          Last Modified
          {' '}
          {getModifiedDate(script)}
          {' '}
          by
          {' '}
          {script.lastEditedBy}
        </span>
        )}
      </header>
      <section className={css.preview__info}>
        <div className={css.preview__content}>
          {script.blocks && script.blocks.map((block, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Block type={block.type} description={block.description} key={index} block={block} category={activeScript.active.category} />
          ))}
        </div>
        <div className={css.preview__buttons}>
          {isTvScripts ? (
            <Button className={css.preview__button} onClick={toggleReviewDoc}><VisibilityIcon /></Button>
          ) : (
            <div className={classNames(css.preview__button, css.preview__file, (script && script.submitted) && css['preview__file--disabled'])}>
              <label htmlFor="videoFile">
                <span><TelegramIcon htmlColor="blue" /></span>
                <input type="file" accept=".mp4,.mov,.mxf" id="videoFile" name="videoFile" onChange={onChangeUploadFile} />
              </label>
            </div>
          )}
          <Button className={css.preview__button} disabled={script.locked} onClick={goToEditPage}><EditIcon /></Button>
          <Button className={css.preview__button} disabled={!(script.owner === user.email)} onClick={lockDocument}>{(script && (script.locked)) ? <LockIcon /> : <LockOpenIcon />}</Button>
          <Button className={css.preview__button} onClick={removeDocument} color="secondary"><DeleteOutlineIcon /></Button>
        </div>
      </section>
      {sendInProgress && (
        <LinearProgress variant="determinate" value={uploadProgress} />
      )}
    </div>
  ) : null
}

export default withRouter(Preview)
